import React, { createRef, Fragment, RefObject, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import { theme } from '@/styles/theme'
import { isWebview } from '@/utils/isWebview'

import ExternalLinkIcon from '@/images/external-link.svg'

import { Seo } from '@/components/Seo'
import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { Spacer } from '@/components/elements/Spacer'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { TermsCard } from '@/components/layouts/TermsCard'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import sanitizeHtml from '@/lib/sanitize-html/sanitizeHtml'

export const query = graphql`
  query {
    allMicrocmsTerms {
      nodes {
        id
        title
        content
      }
    }
  }
`

export default function terms({ data }: { data: any }) {
  const location = useLocation()
  const hash = (typeof window !== 'undefined' && location.hash) as string

  const terms = data.allMicrocmsTerms.nodes

  const breadCrumbs = [
    { name: 'トップ', path: '/' },
    { name: '利用規約', path: '' },
  ]

  const termsTitles = terms.map((element: { id: string; title: string }) => element.title);

  const termsRefs = useRef<RefObject<HTMLHeadingElement>[]>([])
  termsTitles.map((_: any, i: number) => (termsRefs.current[i] = createRef()))

  const scrollToClickedRef = (index: number) =>
    termsRefs.current[index].current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  const scrollToTargetId = (target: 'compensation' | 'cookie') =>
    document.getElementById(target)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  const scrollFromFooter = (name?: string) => {
    //利用規約以外のページでフッターをクリックしたときはURLのハッシュから取得し遷移
    //利用規約ページでフッターをクリックした場合は渡されたnameの値から取得し遷移する
    const targetText = name ?? decodeURI(hash.replace('#', '').replace('/', ''))

    termsRefs.current
      .filter((ref) => ref.current?.textContent?.includes(targetText))[0]
      .current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  }

  useEffect(() => {
    document.querySelectorAll('a').forEach((a) => {
      if (!a.getAttribute('rel')) a.setAttribute('rel', 'noopener noreferrer')
      if (!isWebview && a.innerHTML.includes('https'))
        a.setAttribute('target', '_blank')
    })

    if (hash) {
      const target = decodeURI(hash.replace('#', '').replace('/', ''))

      if (target.includes('補償制度')) scrollToTargetId('compensation')
      else if (target.includes('Cookieの利用')) scrollToTargetId('cookie')
      else scrollFromFooter()
    }
  }, [])

  return (
    <Layout appHide onClickTermsPage={(e) => scrollFromFooter(e)}>
      <Seo
        title="利用規約"
        canonical="https://su-pay.jp/terms/"
        description="SU-PAYについての利用規約はこちら。"
      />
      <Title as="h1" bg>
        利用規約
      </Title>
      <MainWrapper>
        {!isWebview && <BreadCrumbs breadCrumbs={breadCrumbs} />}
        <Spacer h={{ sp: 54, pc: 114 }} />
        {termsTitles.map((title: string, index: number, array: string | any[]) => (
          <Fragment key={title}>
            <TermsCard
              title={title}
              index={index}
              onClick={(e) => scrollToClickedRef(e)}
            />
            <Spacer
              h={{
                sp: index + 1 === array.length ? 0 : 16,
                pc: index + 1 === array.length ? 0 : 20,
              }}
            />
          </Fragment>
        ))}
        {termsTitles.map((title: string, index: number) => {
          const filteredNode = terms.filter((element: { id: string; title: string }) => element.title === title);
          return (
            <Section key={title} isWebview={isWebview}>
              <TitleWrapper pcMarginTop={120} spMarginTop={60}>
                <Title
                  as="h2"
                  color={theme.colors.supayBlue}
                  refProp={termsRefs.current[index]}
                >
                  {title}
                </Title>
              </TitleWrapper>
              <TermsContent
                dangerouslySetInnerHTML={{
                  __html: `${sanitizeHtml(filteredNode[0].content)}`
                }}
              />
            </Section>
          )
        })}
      </MainWrapper>
    </Layout>
  )
}

const Section = styled.section<{ isWebview: boolean }>`
  h2,
  #compensation,
  #cookie {
    padding-top: 100px;
    margin-top: -100px;
  }

  @media screen and (max-width: 1047px) {
    h2,
    #compensation,
    #cookie {
      padding-top: ${({ isWebview }) => (isWebview ? '20px' : '70px')};
      margin-top: ${({ isWebview }) => (isWebview ? '-20px' : '-70px')};
    }
  }
`

const TermsContent = styled.div`
  h3 {
    font-size: 28px;
  }

  p {
    font-size: 20px;
    padding-bottom: 26px;
  }

  div {
    background-color: ${theme.colors.backgroundBlue};

    padding: 33px 37px 7px;
    border-radius: 15px;
  }

  span {
    color: ${theme.colors.supayBlue};
    margin-right: 10px;
  }

  a {
    color: ${(props) => props.theme.colors.supayBlue};

    &:hover {
      opacity: ${(props) => props.theme.opacity};
    }

    &[target='_blank']::after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: 4px;
      vertical-align: text-top;
      background-size: contain;
      background-image: url(${ExternalLinkIcon});
    }
  }

  @media screen and (max-width: 1047px) {
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 18px;
      padding-bottom: 14px;
    }

    div {
      padding: 19px 22px 5px;
    }

    span {
      margin-right: 2px;
    }

    a[target='_blank']::after {
      width: 18px;
      height: 18px;
    }
  }

  @media screen and (max-width: 450px) {
    h3 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }

    a[target='_blank']::after {
      width: 16px;
      height: 16px;
    }
  }
`
